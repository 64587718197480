import './App.css';
import AddressSearch from './AddressSearch';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AddressSearch></AddressSearch>
      </header>
    </div>
  );
}

export default App;
