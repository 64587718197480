import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { Grid, Input, Box, Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { DataGrid } from '@mui/x-data-grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';

const KakaoAutocomplete = () => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = React.useState(null);
  const [searchType, setSearchType] = React.useState("keyword");
  const [inputValue, setInputValue] = useState("");

  const [jusoValue, setJusoValue] = React.useState(null);
  const [jusoInputValue, setJusoInputValue] = React.useState("");
  const [jusoOptions, setJusoOptions] = useState([]);

  const [debounceTic, setDebounceTic] = useState(100);
  const [debounceTic2, setDebounceTic2] = useState(100);

  const kakaoKey = 'b4cfe488cd8ef4b5f7bc9fbcd0f4b055';
  const jusoKey = 'U01TX0FVVEgyMDI0MDExMjEwMTMxOTExNDQyNDI=';

  //카카오 로컬 API
  const fetchKakaoLocations = React.useMemo(() => 
  debounce(async (value, active) => {
    if(!active || value === null || value === "") {
        setOptions([]);
        return undefined;
    }
    try {
      const response = await axios.get(
        `https://dapi.kakao.com/v2/local/search/${searchType}.json?query=${value}`,
        {
          headers: {
            Authorization: `KakaoAK ${kakaoKey}`, // YOUR_KAKAO_API_KEY를 발급받은 키로 대체해주세요.
          },
        }
      );
      console.log(searchType);

      const locations = response.data.documents.map((location) => ({
        label: location.place_name,
        value: location.address_name,
        id: location.id,
        category : location.category_name,
        phone: location.phone,
        x : location.x,
        y : location.y,
        url : location.place_url
      }));

      setOptions([]);
      setOptions(locations);

    } catch (error) {
      console.error('Error fetching Kakao location data:', error);
    }
  }, debounceTic), []);
  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
        setOptions(value ? [value] : []);
        return undefined;
      }

    fetchKakaoLocations(inputValue, active);

    return () => {
        active = false;
    };
  }, [value, inputValue, fetchKakaoLocations])


  // 국가주소시스템 API
  const fetchJuso = React.useMemo(() => 
  debounce(async (jusoValue, active) => {
    if(!active || jusoValue === null || jusoValue === "") {
      setJusoOptions([]);
      return undefined;
    }

    try{
      const response = await axios.get(
        `https://business.juso.go.kr/addrlink/addrLinkApi.do?currentPage=1&countPerPage=100&keyword=${jusoValue}&confmKey=${jusoKey}&resultType=json`
      );

      console.log(response);
      const locations = response.data.results.juso.map((location) => ({
        label: location.bdNm,
        value: location.roadAddr,
        id: location.buldMnnm,
        engAddr : location?.engAddr,
        rn : location?.rn,
        emdNm : location?.emdNm,
        zipNo : location?.zipNo,
        roadAddrPart2 : location?.roadAddrPart2,
        emdNo : location?.emdNo,
        sggNm : location?.sggNm,
        jibunAddr : location?.jibunAddr,
        siNm : location?.siNm,
        roadAddrPart1 : location?.roadAddrPart1,
        bdNm : location?.bdNm,
        admCd : location?.admCd,
        udrtYn : location?.udrtYn,
        lnbrMnnm : location?.lnbrMnnm,
        roadAddr : location?.roadAddr,
        lnbrSlno : location?.lnbrSlno,
        buldMnnm : location?.buldMnnm,
        bdKdcd : location?.bdKdcd,
        liNm : location?.liNm,
        rnMgtSn : location?.rnMgtSn,
        mtYn : location?.mtYn,
        bdMgtSn : location?.bdMgtSn,
        buldSlno : location?.buldSlno,
    }));

    setJusoOptions([]);
    setJusoOptions(locations);

  } catch (error) {
    console.error('Error fetching juso location data:', error);
  }
}, debounceTic2), []);
  React.useEffect(() => {
    let active = true;
    if (jusoInputValue === '') {
        setJusoOptions(jusoValue ? [jusoValue] : []);
        return undefined;
      }

    fetchJuso(jusoInputValue, active);

    return () => {
        active = false;
    };
  }, [jusoValue, jusoInputValue, fetchJuso])
    
  const searchTypeOnChange = (event) => {
    console.log(event.target.value);
    setSearchType(event.target.value);
  }
  
  return (
    <Grid>
      <h4>카카오 로컬 API</h4>
  <FormControl>
  <FormLabel id="demo-radio-buttons-group-label">검색 타입</FormLabel>
  <RadioGroup
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue="keyword"
    name="radio-buttons-group"
  >
    <FormControlLabel value="keyword" control={<Radio />} onChange={searchTypeOnChange} label="키워드" />
    <FormControlLabel value="address" control={<Radio />} onChange={searchTypeOnChange} label="주소" />
  </RadioGroup>
</FormControl>
    <Autocomplete
      sx={{ width: 600 }}
      getOptionLabel={(option) => (
        typeof option === 'string' ? option : option.label
      )}
      filterOptions={(x) => x}
      options={options}
      autoComplete
    //   includeInputInList
      autoSelect
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        return (
            <li {...props} key={option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
              {option.label}
                <Typography variant="body2" color="text.secondary">
                  {option.value}
                </Typography>
                <Typography  variant="body2" color="text.secondary">
                  {option.value}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="키워드를 입력하세요" variant="outlined" />
      )}
    />
    <br></br>
    <Paper elevation={3} style={{textAlign: 'left', padding: "10px", color: "#3D4861"}}>
      <Typography variant="h5" gutterBottom fontWeight={700}>
      선택한 주소 상세정보
      </Typography>
    <Typography variant="body1" gutterBottom fontWeight={600}>
        이름 : {value?.label} <br/>
        주소 : {value?.value} <br/>
        분류 : {value?.category} <br/>
        연락처 : {value?.phone} <br/>
        위경도 : {value?.x}, {value?.y} <br/>
        지도링크 : <a href={value?.url}>{value?.url}</a> <br/>
      </Typography>
    </Paper>
    



    <br></br><br></br>
    <h4>국가주소정보시스템 API</h4>
    <Autocomplete
      sx={{ width: 600 }}
      getOptionLabel={(option) => (
        typeof option === 'string' ? option : option.label
      )}
      filterOptions={(x) => x}
      options={jusoOptions}
      autoComplete
    //   includeInputInList
      autoSelect
      filterSelectedOptions
      value={jusoValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...jusoOptions] : jusoOptions);
        setJusoValue(newValue);
        console.log(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setJusoInputValue(newInputValue);
      }}
      renderOption={(props, option) => {
        return (
            <li {...props} key={option.id}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
              {option.label}
                <Typography variant="body2" color="text.secondary">
                  {option.value}
                </Typography>
                <Typography  variant="body2" color="text.secondary">
                  {option.value}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="키워드를 입력하세요" variant="outlined" />
      )}
    />
    <br></br>
    <Paper elevation={3} style={{textAlign: 'left', padding: "10px", color: "#3D4861"}}>
      <Typography variant="h5" gutterBottom fontWeight={700}>
      선택한 주소 상세정보
      </Typography>
      <Typography variant="body1" gutterBottom fontWeight={600}>
               "도로명영문" : {jusoValue?.engAddr} <br/>
               "도로명" :     {jusoValue?.rn} <br/>
               "읍면동명" :   {jusoValue?.emdNm} <br/>
               "우편번호" :   {jusoValue?.zipNo} <br/>
               "도로명주소 참고항목" : {jusoValue?.roadAddrPart2} <br/>
               "읍면동일련번호" : {jusoValue?.emdNo} <br/>
               "시군구명" : {jusoValue?.sggNm} <br/>
               "지번 정보" : {jusoValue?.jibunAddr} <br/>
               "시도명" : {jusoValue?.siNm} <br/>
               "도로명주소(참고항목 제외)" : {jusoValue?.roadAddrPart1} <br/>
               "건물명" : {jusoValue?.bdNm} <br/>
               "행정구역코드" : {jusoValue?.admCd} <br/>
               "지하여부" : {jusoValue?.udrtYn} <br/>
               "지번본번" : {jusoValue?.lnbrMnnm} <br/>
               "전체 도로명주소" : {jusoValue?.roadAddr} <br/>
               "지번부번" : {jusoValue?.lnbrSlno} <br/>
               "건물본번" : {jusoValue?.buldMnnm} <br/>
               "공동주택여부" : {jusoValue?.bdKdcd} <br/>
               "법정리명" : {jusoValue?.liNm} <br/>
               "도로명코드" : {jusoValue?.rnMgtSn} <br/>
               "산여부" : {jusoValue?.mtYn} <br/>
               "건물관리번호" : {jusoValue?.bdMgtSn} <br/>
               "건물부번" : {jusoValue?.buldSlno} <br/>
      </Typography>
    </Paper>
    </Grid>

    
  );
};

export default KakaoAutocomplete;